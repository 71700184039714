import { Link } from "react-router-dom";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <>
      <section className="error-container">
        <span>
          <span>4</span>
        </span>
        <span>0</span>
        <span>
          <span>4</span>
        </span>
      </section>
      <div className="not-found-text">
        Oooops! Looks like this page does not exist.
      </div>
      <div className="link-container">
        <Link to="/">Visit main page</Link>
      </div>
    </>
  );
};

export default NotFound;
