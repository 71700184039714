import "./Signature.scss";

const Signature = () => {
    return (
        <div className="signature-container">
            <a className="signature" href="https://dominykasstumbras.com/">
                Site created by Dominykas Stumbras
            </a>
        </div>
    );
};

export default Signature;