import { config, useApiCall } from "api";
import { Button } from "components/UI";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { MAIN_LEAGUES, SubscriptionLeague } from "utils";

import champions from "assets/images/competitions/champions.png";
import eurocup from "assets/images/competitions/eurocup.jpg";
import euroleague from "assets/images/competitions/euroleague.jpg";

import "./HomeCompetitions.scss";

const HomeCompetitions = () => {
  const { makeRequest: getLeagues, response } = useApiCall<SubscriptionLeague[]>();
  const leagues = response?.data;

  useEffect(() => {
    getLeagues(config.API_ENDPOINTS.LEAGUES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLeagueUrl = (leagueName: string) => {
      return `${config.APP_PATHS.STATISTICS}?league=${leagueName}`;
  };

  const domesticLeagues = leagues?.map((league) => {
    if (MAIN_LEAGUES.includes(league?.name)) {
      return null;
    }
    return (
      <div className={"logo"} key={league?.name}>
        <Link to={getLeagueUrl(league?.name)}>
          <Button className="m-1">{league?.name}</Button>
        </Link>
      </div>
    );
  });

  return (
    <section id="homeCompetitions" className="homeCompetitions">
      <h2>Competitions</h2>
      <div className="section-divider" />

      <div className="logos">
        <div className="logo">
          <Link to={getLeagueUrl("EuroLeague")}>
            <h2>EuroLeague</h2>
            <img src={euroleague} alt="euroleague statistics" />
          </Link>
        </div>
        <div className="logo">
          <Link to={getLeagueUrl("EuroCup")}>
            <h2>EuroCup</h2>
            <img src={eurocup} alt="eurocup statistics" />
          </Link>
        </div>
        <div className="logo">
          <Link to={getLeagueUrl("Champions League")}>
            <h2>Champions</h2>
            <img className="champions-img" src={champions} alt="champions statistics" />
          </Link>
        </div>
      </div>

      <h2 className="centered domestic-leagues-title">Other Leagues</h2>
      <div className="domestic-leagues section-divider" />
      <div className="centered domestic-leagues">{domesticLeagues}</div>
    </section>
  );
};

export default HomeCompetitions;
