export const addUrlParam = (key: string, value: string) => {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  let newUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newUrl }, "", newUrl);
};

export const deleteUrlParam = (key: string) => {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);

  let newUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();

  window.history.pushState({ path: newUrl }, "", newUrl);
};

export const getUrlParam = (key: string) => {
  let searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};

export const getCurrentUrlParamsString = () => {
  let currentParamsString = new URLSearchParams(
    window.location.search
  ).toString();

  return currentParamsString;
};
