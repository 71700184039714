import { config } from "api";
import { Form, Input, PasswordInput } from "components";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { clearState, login, selectState, useAppDispatch, useAppSelector } from "state";
import { InputErrors } from "utils/inputErrorsEnum";
import { validateInputNotEmpty } from "utils/inputValidation";
import { LoginUserData } from "utils/types";

import "./Login.scss";

const initialFormErrors = {
  email: false,
  pass: false,
};

const Login = () => {
  const dispatch = useAppDispatch();
  const { isLoading, errorMessage } = useAppSelector(selectState);

  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);

  const [formErrors, setFormErrors] = useState(initialFormErrors);

  const validateForm = () => {
    let errors = { ...initialFormErrors };
    let isValidationOk = true;

    if (validateInputNotEmpty(emailRef.current?.value) === false) {
      errors.email = true;
      isValidationOk = false;
    }

    if (validateInputNotEmpty(passRef.current?.value) === false) {
      errors.pass = true;
      isValidationOk = false;
    }

    setFormErrors(errors);
    return isValidationOk;
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (validateForm() === false) {
      return;
    }

    const userData: LoginUserData = {
      email: emailRef.current?.value,
      password: passRef.current?.value,
    };

    dispatch(login(userData));
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  return (
    <section className="login-form">
      <Form
        onSubmit={handleFormSubmit}
        errorText={errorMessage ? errorMessage : ""}
        isLoading={isLoading}
        submitButtonName="Log In"
        childrenAfterSubmitButton={
          <p className="centered forgot-password-link">
            <Link to={config.APP_PATHS.FORGOT_PASSWORD}>Forgot password?</Link>
          </p>
        }
      >
        <Input
          name="Email"
          ref={emailRef}
          validationFunc={validateInputNotEmpty}
          errorText={InputErrors.EMPTY_EMAIL}
          formError={formErrors.email}
          onChange={() => {
            setFormErrors({ ...formErrors, email: false });
          }}
        />

        <PasswordInput
          name="Password"
          ref={passRef}
          validationFunc={validateInputNotEmpty}
          errorText={InputErrors.EMPTY_PASSWORD}
          formError={formErrors.pass}
          onChange={() => {
            setFormErrors({ ...formErrors, pass: false });
          }}
        />
      </Form>

      <div className="link-div">
        <span>Don't have an account?</span>
        <Link to={config.APP_PATHS.SIGNUP}>Sign Up</Link>
      </div>
    </section>
  );
};

export default Login;
