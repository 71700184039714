import { config } from "api";
import { Button } from "components";
import { useHistory } from "react-router-dom";
import { ReactComponent as Success } from "../../assets/icons/success.svg";
import "./CheckoutConfirmed.scss";

const CheckoutConfirmed = () => {
  const history = useHistory();
  return (
    <>
      <div className="checkout-confirmed">
        <div className="checkout-confirmed-card">
          <Success width={100} height={100} color="#198754" />
          <h4>Thank You for ordering!</h4>
        </div>
        <div className="checkout-confirmed-details">
          <h5>
            Your subscription will appear automatically once payment has been processed.
          </h5>
          <div className="m-2">
            <Button onClick={() => history.push(config.APP_PATHS.MAIN)}>Back</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutConfirmed;
