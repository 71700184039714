import { config } from "api";
import { useState } from "react";
import { Link } from "react-router-dom";

import "./Nav.scss";

const Nav = () => {

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        className="menu-btn"
      >
        <span className={openMenu ? "menu-btn__burger open" : "menu-btn__burger"}></span>
      </div>

      <nav className={openMenu ? "open" : "closed"}>
        <ul className="navigation">
          <li>
            <a href="#mainTitle">Home</a>
          </li>
          <li>
            <a href="#competitions">Competitions</a>
          </li>
          <li>
            <a href="#contacts">Contacts</a>
          </li>
          <li>
            <Link to={config.APP_PATHS.LOGIN}>
              Log In
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Nav;
