import "./Dropdown.scss";
import { useEffect, useRef, useState } from "react";
import { League, Season, SubscriptionLeague, View } from "utils";

import { ReactComponent as LockIcon } from "assets/icons/lock.svg";

type CustomFilterProps = {
  current: string;
  options: SubscriptionLeague[] | League[] | Season[] | View[];
  handleFilterChange: Function;
  isMedia: boolean;
};

const Dropdown = ({ options, current, handleFilterChange, isMedia }: CustomFilterProps) => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      if (open && ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const handleChange = (event: any) => {
    if (isMedia) {
      handleFilterChange(event.target.value);
    } else {
      setOpen(false);
      handleFilterChange(event.target.innerText);
    }
  };

  const checkIsAllowedView = (option: SubscriptionLeague | League | Season | View): boolean | undefined => {
    if ("is_allowed" in option && option.is_allowed === false) {
      return false;
    }
    return true;
  };

  let content;

  if (isMedia) {
    content = (
      <select className="media-filter-select" onChange={handleChange} value="default">
        <option value="default" disabled hidden>
          {current}
        </option>

        {options.map((option) =>
          checkIsAllowedView(option) ? (
            <option key={option.id} value={option.name}>
              {option.name}
            </option>
          ) : (
            <option key={option.id} value={option.name} style={{ backgroundColor: "#ebebeb" }}>
              {option.name} &#128274;
            </option>
          )
        )}
      </select>
    );
  } else {
    content = (
      <div
        ref={ref}
        onClick={() => {
          setOpen(!open);
        }}
        className="custom-filter-wrapper"
      >
        <div className={open ? "filter open" : "filter"}>
          <div className="filter-header border-bottom">
            <div className="filter-title">{current}</div>
            {open ? <span className="arrow">&#9650;</span> : <span className="arrow">&#9660;</span>}
          </div>

          <div className="filter-options">
            {options.map((option) => (
              <span
                className={`filter-option selected ${checkIsAllowedView(option) ? "" : "not-allowed"}`}
                key={option.id}
                onClick={handleChange}
              >
                {option.name}
                {checkIsAllowedView(option) === false && (
                  <span>
                    <LockIcon width={20} height={20} />
                  </span>
                )}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default Dropdown;
