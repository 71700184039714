import { Api, config, useApiCall } from "api";
import { Dropdown, HomeHeader } from "components";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import {
  addUrlParam,
  deleteUrlParam,
  getUrlParam,
  League,
  Season,
  View,
  ViewSelection,
} from "utils";
import { selectIsAuth, useAppSelector } from "state";
import info from "assets/icons/info.png";

import "./Statistics.scss";

const initialCurentSelect: ViewSelection = {
  league: "Select League",
  season: "Select Season",
  view: "Select View",
};

const Statistics = () => {
  window.scrollTo(0, 0);
  let mql = window.matchMedia("(max-width: 600px)");

  const history = useHistory();

  const isAuth = useAppSelector(selectIsAuth);

  const currentLeagueNameParam = getUrlParam("league");
  const currentSeasonNameParam = getUrlParam("season");
  const currentViewNameParam = getUrlParam("view");

  const [leagues, setStatisticsData] = useState<League[]>([]);
  const [currentSelect, setCurrentSelect] = useState(initialCurentSelect);
  const [selectedLeague, setSelectedLeague] = useState<League>();
  const [selectedSeason, setSelectedSeason] = useState<Season>();
  const [embedUrl, setEmbedUrl] = useState<string>("");
  const [showSelections, setShowSelections] = useState(!currentLeagueNameParam);

  const {
    makeRequest: getStatistics,
    loading,
    response,
  } = useApiCall<League[]>();
  const { makeRequest: postLogs } = useApiCall();

  useEffect(() => {
    getStatistics(
      Api.ENDPOINTS.STATISTICS,
      { is_mobile_view: mql.matches },
      {},
      Api.METHODS.GET,
      !isAuth
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!response) {
      return;
    }

    configureInitialStatisticsData(response.data);

    return () => {
      toast.remove("loading");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const configureInitialStatisticsData = (leagues: League[]) => {
    setStatisticsData(leagues);

    let leagueName = currentLeagueNameParam;
    let seasonName = currentSeasonNameParam;
    let viewName = currentViewNameParam;

    if (leagueName === null) {
      return;
    }
    handleLeagueChanged(leagueName, leagues, {
      league: leagueName,
      season: seasonName || "",
      view: viewName || "",
    });
  };

  const handleLeagueChanged = (
    selectedLeagueName: string,
    data = leagues,
    initialSelection: ViewSelection | null = null
  ): League | undefined => {
    const selectedLeague: League | undefined = data.find(
      (league: League) => league.name === selectedLeagueName
    );

    setSelectedSeason(undefined);
    setEmbedUrl("");

    deleteUrlParam("league");
    deleteUrlParam("season");
    deleteUrlParam("view");

    if (selectedLeague === undefined) {
      return;
    }

    setCurrentSelect({
      league: selectedLeagueName,
      season: "",
      view: "",
    });

    addUrlParam("league", selectedLeagueName);
    if (initialSelection?.season) {
      handleSeasonsChanged(
        initialSelection.season,
        selectedLeague,
        initialSelection
      );
    } else if (selectedLeague.seasons.length > 0) {
      handleSeasonsChanged(
        selectedLeague.seasons[0].name,
        selectedLeague,
        initialSelection
      );
    }
    setSelectedLeague(selectedLeague);
    return selectedLeague;
  };

  const handleSeasonsChanged = (
    selectedSeasonName: string,
    data: League | undefined = selectedLeague,
    initialSelection: ViewSelection | null = null
  ): Season | undefined => {
    if (data === undefined) {
      return;
    }

    deleteUrlParam("season");
    deleteUrlParam("view");

    const selectedSeason: Season | undefined = data.seasons.find(
      (season: Season) => season.name === selectedSeasonName
    );

    if (selectedSeason === undefined) {
      return;
    }

    setCurrentSelect({
      league: data.name,
      season: selectedSeasonName,
      view: "",
    });

    addUrlParam("season", selectedSeasonName);
    const allowedView = selectedSeason.embeds.find((embed) => embed.is_allowed);
    if (initialSelection?.view) {
      handleViewsChanged(initialSelection.view, selectedSeason, data.name);
    } else if (allowedView) {
      handleViewsChanged(
        selectedSeason.embeds[0].name,
        selectedSeason,
        data.name
      );
    }
    setSelectedSeason(selectedSeason);
    return selectedSeason;
  };

  const handleViewsFilerChanged = (selectedViewName: string) => {
    setShowSelections(false);
    handleViewsChanged(selectedViewName);
  };

  const handleViewsChanged = (
    selectedViewName: string,
    data: Season | undefined = selectedSeason,
    leagueName: string = currentSelect.league
  ) => {
    if (data === undefined) {
      return;
    }

    deleteUrlParam("view");

    const selecetedView: View | undefined = data.embeds.find(
      (view: View) => view.name === selectedViewName
    );

    if (selecetedView === undefined) {
      return;
    }

    postLogs(
      Api.ENDPOINTS.LOGGING,
      {},
      { embed: selecetedView.id },
      Api.METHODS.POST,
      !isAuth
    );
    if (selecetedView.is_allowed === false) {
      history.push(config.APP_PATHS.SUBSCRIPTIONS);
      return;
    }

    setCurrentSelect({
      league: leagueName,
      season: data.name,
      view: selectedViewName,
    });

    addUrlParam("view", selectedViewName);
    setEmbedUrl(selecetedView.url);
  };

  if (loading) {
    toast.loading("Loading...", {
      id: "loading",
    });
  } else {
    toast.remove("loading");
  }

  let statisticsSelect = (
    <div className={mql.matches ? "media-select" : "custom-select"}>
      <Dropdown
        current={currentSelect.league}
        options={leagues}
        handleFilterChange={handleLeagueChanged}
        isMedia={mql.matches}
      />

      {selectedLeague && (
        <Dropdown
          current={currentSelect.season}
          options={selectedLeague?.seasons}
          handleFilterChange={handleSeasonsChanged}
          isMedia={mql.matches}
        />
      )}

      {selectedSeason && (
        <Dropdown
          current={currentSelect.view}
          options={selectedSeason?.embeds}
          handleFilterChange={handleViewsFilerChanged}
          isMedia={mql.matches}
        />
      )}
    </div>
  );
  statisticsSelect = mql.matches ? (
    <>
      <div
        className={`selections-btn ${embedUrl ? "selections-btn-show" : ""}`}
        onClick={() => {
          setShowSelections(!showSelections);
        }}
      >
        {showSelections ? "Hide" : "Show"} selections
      </div>
      <div className={`selections ${showSelections ? "selections-show" : ""}`}>
        <div className="info-div">
          <img src={info} alt="info" />
          For more analytics, please use desktop version.
        </div>
        {statisticsSelect}
      </div>
    </>
  ) : (
    statisticsSelect
  );

  return (
    <div className="statistics-page">
      <HomeHeader hideStatistics={true} />
      <section className="statistics">
        {statisticsSelect}
        <iframe
          className="statistics-iframe"
          title="embed"
          src={embedUrl}
          style={{
            width: "100%",
            padding: "0",
          }}
        />
      </section>
    </div>
  );
};

export default Statistics;
