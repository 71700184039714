export const config = {
  API_BASE_URL_DEV: "",
  API_BASE_URL_PROD: `https://api.${window.location.hostname}`,
  API_ENDPOINTS: {
    ACCOUNT_SUBSCRIPTION: "api/users/subscription/",
    LOGIN: "api/users/login/",
    REFRESH: "api/users/refresh/",
    REGISTER: "api/users/register/",
    CONFIRM_EMAIL: "api/users/confirm-email/",
    REQUEST_PASSWORD_RESET: "api/users/request-password-reset/",
    PASSWORD_RESET: "api/users/password-reset/",
    STATISTICS: "api/statistics/",
    LOGGING: "api/logging/",
    LEAGUES: "api/statistics/leagues/",
    PRICES: "api/orders/prices/",
    ORDERS: "api/orders/",
    ORDER: (orderID: number) => `api/orders/${orderID}/`,
  },

  APP_PATHS: {
    MAIN: "/",
    LOGIN: "/login",
    SIGNUP: "/signup",
    CONFIRM_EMAIL: "/confirm-email",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    STATISTICS: "/statistics",
    SUBSCRIPTIONS: "/subscriptions",
    CHECKOUT: "/checkout",
    CHECKOUT_CONFIRMED: "/checkout-confirmed",
  },
};
