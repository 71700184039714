import { ExpiryStorage } from "utils";

export const setToLocalStorage = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const removeFromLocalStorage = (key: string) => {
  return localStorage.removeItem(key);
};

export const setToLocalStorageWithExpiry = (key: string, value: string, ttlDays: number) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttlDays * 86400000,
  };
  setToLocalStorage(key, JSON.stringify(item))
};

export const getFromLocalStorageWithExpiry = (key: string, removeMiss = true): ExpiryStorage => {
  const itemStr = getFromLocalStorage(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return { item: null, expired: true };
  }
  let item;
  try {
    item = JSON.parse(itemStr);
  } catch (e) {
    console.log(e);
    return { item: null, expired: true };
  }
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage and return null
    if (removeMiss){
      removeFromLocalStorage(key);
    }
    return {item: item.value, expired: true};
  }
  return {item: item.value, expired: false};
};

export const STORAGE_KEYS = {
  ACCESS: "Access",
  REFRESH: "Refresh",
};
