import { config } from "api";
import { Signature } from "components";
import Nav from "components/Nav/Nav";
import { Link } from "react-router-dom";

import email from "assets/icons/email.png";
import twitter from "assets/icons/x.svg";
import linkedin from "assets/icons/linkedin.png";

import "./Layout.scss";

type LayoutProps = {
  children: any;
};

export const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Nav />
      <main>{children}</main>
      <footer id="contacts">
        <div className="section-divider" />
        <div className="centered">
          <h4 className="footer-text">
            <Link className="link" to={config.APP_PATHS.SIGNUP}>
              Sign up
            </Link>{" "}
            for full access or contact for more information!
          </h4>
        </div>
        <div className="social-icons">
          <a href="https://www.twitter.com/bballytics/" target="_blank" rel="noreferrer">
            <img src={twitter} alt="twitter icon" />
          </a>
          <a href="https://www.linkedin.com/company/bballytics/" target="_blank" rel="noreferrer">
            <img src={linkedin} alt="linkedin icon" />
          </a>
          <a href="mailto:info@bballytics.com">
            <img src={email} alt="email icon" />
          </a>
        </div>

        <div className="email centered"><b>info@bballytics.com</b></div>

        <div className="creator">
          <p>Mykolas Stumbras</p>
        </div>

        <Signature/>
      </footer>
    </>
  );
};

export default Layout;
