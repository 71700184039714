import { Prices } from "utils";
import "./SubscriptionsTable.scss";

type SubscriptionsTableProps = {
  prices: Prices | null;
  subscribedUntil: Date | null;
  isYearlySubscription: boolean;
  setIsYearlySubscription: Function;
};

const SubscriptionsTable = ({
  prices,
  subscribedUntil,
  isYearlySubscription,
  setIsYearlySubscription,
}: SubscriptionsTableProps) => {
  if (!prices) {
    return <></>;
  }
  return (
    <div className="subscriptions-table">
      <div className="first table-row">
        <div className="table-data"></div>
        <div
          className="table-data cursor-pointer user-select-none"
          onClick={() => setIsYearlySubscription(true)}
        >
          <h5>
            <input
              type="radio"
              id="price"
              checked={isYearlySubscription}
              value={String(isYearlySubscription)}
              onChange={() => setIsYearlySubscription(true)}
            />{" "}
            Yearly<span className="other-information"> / per month</span>
          </h5>
        </div>
        <div
          className="table-data-small cursor-pointer user-select-none"
          onClick={() => setIsYearlySubscription(false)}
        >
          <h5>
            <input
              type="radio"
              id="price"
              checked={!isYearlySubscription}
              value={String(!isYearlySubscription)}
              onChange={() => setIsYearlySubscription(false)}
            />{" "}
            Monthly
          </h5>
        </div>
        {subscribedUntil && (
          <div className="table-data-small">
            <h5>Subscribed until</h5>
          </div>
        )}
      </div>
      <div className={`table-row cursor-pointer`}>
        <div className="table-data">
          <h5 className="table-data-item">Unlock full access</h5>
        </div>

        <div className="table-data">
          <h5>
            {prices.yearly}€
            <span className="other-information">
              {" "}
              / {(prices.yearly && prices.yearly / 12).toPrecision(3)}€
            </span>
          </h5>
        </div>
        <div className="table-data-small">
          <h5>{prices.monthly}€</h5>
        </div>
        {subscribedUntil && (
          <div className="table-data-small">
            <h5 className="expiration">
              {new Date(subscribedUntil).toLocaleDateString("lt-LT")}
            </h5>
          </div>
        )}
      </div>

      <div className="table-row total">
        <div className="total">
          <h5>
            Total: {isYearlySubscription ? prices.yearly : prices.monthly}€
          </h5>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsTable;
