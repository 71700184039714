import React, { useState } from "react";
import Input, { InputProps } from "../Input/Input";

import view from "assets/icons/view.png";
import hidden from "assets/icons/hidden.png";

const PasswordInput = React.forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>) => {
  const [isTextView, setIsTextView] = useState(false);

  return (
    <Input
      ref={ref}
      name={props.name}
      validationFunc={props.validationFunc}
      errorText={props.errorText}
      formError={props.formError}
      autoComplete={props.autoComplete}
      onChange={props.onChange}
      onBlur={props.onBlur}
      type={isTextView ? "text" : "password"}
    >
      <img
        className="view-pass-img"
        src={isTextView ? hidden : view}
        alt="eye"
        onClick={() => setIsTextView(!isTextView)}
      />
    </Input>
  );
});

export default PasswordInput;
