import { useRef, useState } from "react";
import { DefaultErrors, InputErrors, postRequest, validateEmail } from "utils";
import { Card, Form, Input } from "components";
import { Api } from "api";
import ReCAPTCHA from "react-google-recaptcha";

const initialFormErrors = {
  email: false,
  recaptcha: false,
};

const ForgotPassword = () => {
  let mql = window.matchMedia("(max-width: 600px)");

  const emailRef = useRef<HTMLInputElement>(null);
  const recaptchaRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  const [linkSent, setLinkSent] = useState(false);

  const validateForm = () => {
    let errors = { ...initialFormErrors };
    let isValidationOk = true;

    if (validateEmail(emailRef.current?.value) === false) {
      setIsLoading(false);
      errors.email = true;
      isValidationOk = false;
    }

    if (recaptchaRef.current.getValue() === "") {
      errors.recaptcha = true;
      isValidationOk = false;
    }

    setFormErrors(errors);
    return isValidationOk;
  };

  const sendRequestPasswordReset = (userData: { email: string | undefined, recaptcha_token: string }) => {
    postRequest(Api.ENDPOINTS.REQUEST_PASSWORD_RESET, userData)
      .then(() => {
        setLinkSent(true);
      })
      .catch(() => {
        setError(DefaultErrors.DEFAULT);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (validateForm() === false) {
      return;
    }

    const userData = {
      email: emailRef.current?.value,
      recaptcha_token: recaptchaRef.current.getValue(),
    };

    sendRequestPasswordReset(userData);
  };

  return (
    <section>
      {linkSent && (
        <Card>
          <h5>Email was sent if specified account exists.</h5>
          <p>
            Please check your <u>spam folder</u> if you cannot find the email.
          </p>
        </Card>
      )}

      {!linkSent && (
        <Form
          onSubmit={handleSubmit}
          submitButtonName="Send Email"
          isLoading={isLoading}
          errorText={error !== "" ? error : ""}
        >
          <h5 className="centered">Enter your email and we will send you a link to get back into your account.</h5>

          <Input
            name="Email"
            ref={emailRef}
            validationFunc={validateEmail}
            errorText={InputErrors.INCORRECT_EMAIL}
            formError={formErrors.email}
            onChange={() => {
              setFormErrors({ ...formErrors, email: false });
            }}
          />

          <div className="recaptcha-div">
            <ReCAPTCHA
              size={mql.matches ? "compact" : "normal"}
              sitekey="6Ld7WkEdAAAAAJJ7NRuDfw3l1G3Tq-xvnISEWjHw"
              ref={recaptchaRef}
              onChange={() => {
                setFormErrors({ ...formErrors, recaptcha: false });
              }}
            ></ReCAPTCHA>
            {formErrors.recaptcha === true && <div className="error-message">{InputErrors.RECAPTCHA}</div>}
          </div>
        </Form>
      )}
    </section>
  );
};

export default ForgotPassword;
