export enum token_lifetime {
  accessToken = 1,
  refreshToken = 30
}

export enum session_storage {
  basketItems = "basketItems",
  orderId = "orderId",
}

export const MAIN_LEAGUES = ["EuroLeague", "EuroCup", "Champions League"];

export const BBALLYTICS_BUNDLE = "BBallytics bundle";
