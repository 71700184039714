import check from "assets/images/vision/check.png";
import stats from "assets/images/vision/stats.png";
import analytics from "assets/images/vision/analytics.png";

import "./Vision.scss";

const Vision = () => {
  return (
    <section className="vision">
      <div className="information">
        <div>
          <img src={check} alt="check mark" />
          <h2>Up to date</h2>
          <h3>Calculations are based on cleaned play-by-play data</h3>
        </div>
        <div>
          <img src={analytics} alt="analytics" />
          <h2>Informative</h2>
          <h3>Detailed player to team based analytics</h3>
        </div>
        <div>
          <img src={stats} alt="stats" />
          <h2>Easy to use</h2>
          <h3>Interactive dashboards Standardised data</h3>
        </div>
      </div>
    </section>
  );
};

export default Vision;
