import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { Card, Form, Input, PasswordInput } from "components";
import {
  DefaultErrors,
  InputErrors,
  postRequest,
  SignupUserData,
  validateEmail,
  validatePassword,
  validatePasswordConfirmation
} from "utils";

import { Api, config } from "api";
import "./Signup.scss";

const initialErrorState = {
  email: false,
  pass: false,
  confirmPass: false,
  recaptcha: false,
};

const Signup = () => {
  window.scrollTo(0, 0);
  let mql = window.matchMedia("(max-width: 600px)");

  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const confirmPassRef = useRef<HTMLInputElement>(null);
  const recaptchaRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState("");

  const [formErrors, setFormErrors] = useState(initialErrorState);

  const [signupConfirmation, setSignupConfirmation] = useState(false);

  const validateForm = () => {
    let errors = { ...initialErrorState };
    let isValidationOk = true;

    if (validateEmail(emailRef.current?.value) === false) {
      errors.email = true;
      isValidationOk = false;
    }

    if (validatePassword(passRef.current?.value) === false) {
      errors.pass = true;
      isValidationOk = false;
    }

    if (validatePasswordConfirmation(passRef.current?.value, confirmPassRef.current?.value) === false) {
      errors.confirmPass = true;
      isValidationOk = false;
    }

    if (recaptchaRef.current.getValue() === "") {
      errors.recaptcha = true;
      isValidationOk = false;
    }

    setFormErrors(errors);
    return isValidationOk;
  };

  const sendSignupRequest = (userData: SignupUserData) => {
    postRequest(Api.ENDPOINTS.REGISTER, userData)
      .then((response) => {
        if (response.status === 201) {
          setSignupConfirmation(true);
        }
      })
      .catch((error) => {
        const message: string = error.response.status === 400 ? error.response.data : DefaultErrors.DEFAULT;
        setResponseErrorMessage(message);
        recaptchaRef.current.reset();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (validateForm() === false) {
      setIsLoading(false);
      return;
    }

    const userData: SignupUserData = {
      email: emailRef.current?.value,
      password: passRef.current?.value,
      recaptcha_token: recaptchaRef.current.getValue(),
    };

    sendSignupRequest(userData);
  };

  const handlePasswordConfirmationBlur = () => {
    if (validatePasswordConfirmation(passRef.current?.value, confirmPassRef.current?.value) === false) {
      setFormErrors({ ...formErrors, confirmPass: true });
    }
  };

  return (
    <section className="signup-form">
      {signupConfirmation && (
        <Card>
          <div className="centered">
            <h5>Please check your email to confirm registration.</h5>
            <p>
              Please check your <u>spam folder</u> if you cannot find the email.
            </p>
          </div>
        </Card>
      )}

      {!signupConfirmation && (
        <Form
          onSubmit={handleFormSubmit}
          errorText={responseErrorMessage !== "" ? responseErrorMessage : ""}
          isLoading={isLoading}
          submitButtonName="Sign Up"
        >
          <h5 className="centered">Sign up and get full access today!</h5>

          <Input
            name="Email"
            ref={emailRef}
            validationFunc={validateEmail}
            errorText={InputErrors.INCORRECT_EMAIL}
            formError={formErrors.email}
            onChange={() => {
              setFormErrors({ ...formErrors, email: false });
            }}
          />

          <PasswordInput
            name="Password"
            ref={passRef}
            validationFunc={validatePassword}
            errorText={InputErrors.INCORRECT_PASSWORD}
            formError={formErrors.pass}
            autoComplete="new-password"
            onChange={() => {
              setFormErrors({ ...formErrors, pass: false });
            }}
          />

          <PasswordInput
            name="Confirm Password"
            ref={confirmPassRef}
            errorText={InputErrors.PASSWORD_CONFIRMATION_MISMATCH}
            formError={formErrors.confirmPass}
            autoComplete="new-password"
            onChange={() => {
              setFormErrors({ ...formErrors, confirmPass: false });
            }}
            onBlur={handlePasswordConfirmationBlur}
          />

          <div className="recaptcha-div">
            <ReCAPTCHA
              size={mql.matches ? "compact" : "normal"}
              sitekey="6Ld7WkEdAAAAAJJ7NRuDfw3l1G3Tq-xvnISEWjHw"
              ref={recaptchaRef}
              onChange={() => {
                setFormErrors({ ...formErrors, recaptcha: false });
              }}
            ></ReCAPTCHA>
            {formErrors.recaptcha === true && <div className="error-message">{InputErrors.RECAPTCHA}</div>}
          </div>
        </Form>
      )}

      <div className="link-div">
        <span>Have an account?</span>
        <Link to={config.APP_PATHS.LOGIN}>Log In</Link>
      </div>
    </section>
  );
};

export default Signup;
