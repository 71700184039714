import logo from "assets/images/logo/logo-1000.png";
import "./MainTitle.scss";

const MainTitle = () => {
  return (
    <section id="mainTitle" className="mainTitle">
      <div className="logo">
        <img src={logo} alt="basketball analytics" />
      </div>

      <div className="heading">
        <h1>BBALLYTICS</h1>
        <h2>
          Detailed basketball analytics on European <span>leagues</span>, <span>teams</span> and <span>players</span>!
        </h2>
      </div>
    </section>
  );
};

export default MainTitle;
