import { Api, config, useApiCall } from "api";
import { ReactComponent as AccountIcon } from "assets/icons/account.svg";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Account } from "utils";
import "./AccountSubscription.scss";
import { setSubscribedUntil, useAppDispatch } from "state";

const AccountSubscription = () => {
  const dispatch = useAppDispatch();
  const { makeRequest: getAccountSubscription, response } = useApiCall<Account>();

  useEffect(() => {
    getAccountSubscription(Api.ENDPOINTS.ACCOUNT_SUBSCRIPTION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response?.status !== 200) { return; }
    dispatch(setSubscribedUntil(response.data.expires_at));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])
  

  if (response === null) {
    return null;
  }

  if (response?.data.expires_at === null) {
    return (
      <div className="account-subscription">
        <Link to={config.APP_PATHS.SUBSCRIPTIONS}>
          <AccountIcon width={20} height={20} /> <span>Free account</span>
        </Link>
      </div>
    );
  }

  const daysLeft = Math.ceil((new Date(response.data.expires_at).getTime() - new Date().getTime()) / 86400000);
  return (
    <div className="account-subscription-valid">
      <Link to={config.APP_PATHS.SUBSCRIPTIONS}>
        <AccountIcon width={20} height={20} />
        <span>
          Subscription expires in <b>{daysLeft} day{daysLeft !== 1 && "s"}</b>
        </span>
      </Link>
    </div>
  );
};

export default AccountSubscription;
