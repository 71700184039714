import { Api, config } from "api";
import { Card, Form, PasswordInput } from "components";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  DefaultErrors,
  getUrlParam,
  InputErrors,
  postRequest,
  validatePassword,
  validatePasswordConfirmation
} from "utils";

const initialFormErrors = {
  pass: false,
  confirmPass: false,
};

const ResetPassword = () => {
  const id = getUrlParam("id");
  const token = getUrlParam("token");

  const passRef = useRef<HTMLInputElement>(null);
  const confirmPassRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  const [passwordResetConfirmed, setPasswordResetConfirmed] = useState(false);

  const validateForm = () => {
    let errors = { ...initialFormErrors };
    let isValidationOk = true;

    if (validatePassword(passRef.current?.value) === false) {
      errors.pass = true;
      isValidationOk = false;
    }

    if (validatePasswordConfirmation(passRef.current?.value, confirmPassRef.current?.value) === false) {
      errors.confirmPass = true;
      isValidationOk = false;
    }

    setIsLoading(false);
    setFormErrors(errors);
    return isValidationOk;
  };

  const sendResetPassword = (userData: { id: string | null; token: string | null; password: string | undefined }) => {
    postRequest(Api.ENDPOINTS.PASSWORD_RESET, userData)
      .then(() => {
        setPasswordResetConfirmed(true);
      })
      .catch(() => {
        setError(DefaultErrors.DEFAULT);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (validateForm() === false) {
      return;
    }

    const userData = {
      id: id,
      token: token,
      password: passRef.current?.value,
    };

    sendResetPassword(userData);
  };

  const handlePasswordConfirmationBlur = () => {
    if (validatePasswordConfirmation(passRef.current?.value, confirmPassRef.current?.value) === false) {
      setFormErrors({ ...formErrors, confirmPass: true });
    }
  };

  return (
    <section>
      {passwordResetConfirmed && (
        <Card>
          <h5>Your password has been reset.</h5>
          <br />
          <h5>
            <Link className="link" to={config.APP_PATHS.LOGIN}>
              Log In
            </Link>
          </h5>
        </Card>
      )}

      {!passwordResetConfirmed && (
        <Form
          onSubmit={handleSubmit}
          submitButtonName="Confirm"
          isLoading={isLoading}
          errorText={error !== "" ? error : ""}
        >
          <h5 className="centered">Please enter new password.</h5>

          <PasswordInput
            name="Password"
            ref={passRef}
            validationFunc={validatePassword}
            errorText={InputErrors.INCORRECT_PASSWORD}
            formError={formErrors.pass}
            autoComplete="new-password"
            onChange={() => {
              setFormErrors({ ...formErrors, pass: false });
            }}
          />

          <PasswordInput
            name="Confirm Password"
            ref={confirmPassRef}
            errorText={InputErrors.PASSWORD_CONFIRMATION_MISMATCH}
            formError={formErrors.confirmPass}
            autoComplete="new-password"
            onChange={() => {
              setFormErrors({ ...formErrors, confirmPass: false });
            }}
            onBlur={handlePasswordConfirmationBlur}
          />
        </Form>
      )}
    </section>
  );
};

export default ResetPassword;
