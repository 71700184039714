import LogoLink from "../LogoLink/LogoLink";
import "./Card.scss";

const Card = (props: any) => {
  return (
    <div className="card">
      <LogoLink />
      {props.children}
    </div>
  );
};

export default Card;
