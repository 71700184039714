import LoadingSpinner from "./LoadingSpinner";
import "./LoadingSpinner.scss";

const Loader = () => {
  return (
    <div className="loader">
      <LoadingSpinner />
    </div>
  );
};

export default Loader;
