import { AxiosResponse, AxiosError } from "axios";
import { useState } from "react";
import { Api } from "./api";

export type BaseErrorResponse =
  | {
      non_field_errors?: string[];
    }
  | string[];

const ParseBaseResponse = (
  response: BaseErrorResponse | undefined
): string | undefined | null => {
  const array = Array.isArray(response) ? response : response?.non_field_errors;
  return array?.join(", ") || null;
};

export function useApiCall<T = any>() {
  const [response, setResponse] = useState<AxiosResponse<T> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const makeRequest = (
    endpoint: string,
    queryParams: {} = {},
    body: {} = {},
    method: string = Api.METHODS.GET,
    noAuth: boolean = false
  ) => {
    setError(null);
    setLoading(true);
    Api.request(endpoint, queryParams, body, method, noAuth)
      .then((res) => {
        setResponse(res);
      })
      .catch((axiosError: AxiosError<BaseErrorResponse>) => {
        // TODO if noAuth = false then check if response was 403 and log the user out globally?
        const errorMessage =
          ParseBaseResponse(axiosError.response?.data) || axiosError.message;
        setError(errorMessage);
        console.log(errorMessage, new Date().toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { makeRequest, loading, response, error };
}
