import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { config, useApiCall } from "api";
import Loader from "components/UI/LoadingSpinner/Loader";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Order, session_storage } from "utils";
import { HomeHeader } from "components";

import "./Checkout.scss";

const Checkout = () => {
  const history = useHistory();
  const {
    makeRequest: getCheckoutOrder,
    response,
    loading,
  } = useApiCall<Order>();
  const [order, setOrder] = useState<Order | null>(null);

  useEffect(() => {
    const orderID = Number(sessionStorage.getItem(session_storage.orderId));
    if (orderID) {
      getCheckoutOrder(config.API_ENDPOINTS.ORDER(orderID));
      return;
    }
    history.push(config.APP_PATHS.SUBSCRIPTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response?.status !== 200) {
      return;
    }
    setOrder(response.data);
  }, [response]);

  if (loading || order === null) {
    return <Loader />;
  }

  return (
    <>
      <HomeHeader />
      <section className="checkout">
        <h2 className="centered">Checkout</h2>
        <div className="section-divider" />

        <div className="checkout-table">
          <div className="checkout-table-data">
            <h5 className="item-label">BBallytics Subscription</h5>
            <h5>{order.total}€</h5>
            <h5 className="item-expiration">
              Exp. {new Date(order.expires_at).toLocaleDateString("lt-LT")}
            </h5>
          </div>

          <div className="checkout-total">
            <h4>Total: {order.total}€</h4>
          </div>
        </div>

        <div className="checkout-paypal-wrapper">
          <PayPalScriptProvider
            options={{ "client-id": order.client_id, currency: "EUR" }}
          >
            <PayPalButtons
              className="checkout-paypal"
              style={{ layout: "vertical" }}
              createOrder={(data, actions) => {
                return new Promise((resolve) => {
                  resolve(order.order_id);
                });
              }}
              onApprove={(data, actions) => {
                if (!actions || !actions.order) {
                  return new Promise((resolve) => {
                    resolve();
                  });
                }
                return actions.order.capture().then((details) => {
                  history.push(config.APP_PATHS.CHECKOUT_CONFIRMED);
                });
              }}
            />
          </PayPalScriptProvider>
        </div>
      </section>
    </>
  );
};

export default Checkout;
