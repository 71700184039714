import { Api, config, useApiCall } from "api";
import { Button, HomeHeader } from "components/UI";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Order, Prices, session_storage } from "utils";
import Loader from "components/UI/LoadingSpinner/Loader";
import SubscriptionsTable from "./SubscriptionsTable/SubscriptionsTable";
import { selectSubscribedUntil, useAppSelector } from "state";

import "./Subscriptions.scss";

const Subscriptions = () => {
  const history = useHistory();
  const subscribedUntil = useAppSelector(selectSubscribedUntil);

  const { makeRequest: getPrices, response: pricesResponse, loading: pricesLoading } = useApiCall<Prices>();
  const { makeRequest: postOrders, response: ordersResponse, loading: postOrdersLoading } = useApiCall<Order>();

  const [prices, setPrices] = useState<Prices | null>(null);
  const [isYearlySubscription, setIsYearlySubscription] = useState(true);

  useEffect(() => {
    getPrices(config.API_ENDPOINTS.PRICES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pricesResponse?.status !== 200) { return; }
    setPrices(pricesResponse.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricesResponse]);

  useEffect(() => {
    if (ordersResponse?.data?.id) {
      sessionStorage.setItem(session_storage.orderId, String(ordersResponse.data.id));
      history.push(config.APP_PATHS.CHECKOUT);
    }
  }, [history, ordersResponse]);

  const goToCheckout = () => {
    postOrders(config.API_ENDPOINTS.ORDERS, {}, { is_yearly: isYearlySubscription }, Api.METHODS.POST);
  };

  if (postOrdersLoading || pricesLoading) {
    return <Loader />
  }

  return (
    <>
      <HomeHeader />
      <section className="subscriptions">
        <div>
        <h2 className="centered">Subscriptions</h2>
        <h3 className="centered no-bold">Subscribe to access win probabilities and more!</h3>
        <SubscriptionsTable
          prices={prices}
          subscribedUntil={subscribedUntil}
          isYearlySubscription={isYearlySubscription}
          setIsYearlySubscription={setIsYearlySubscription}
        />
        <div className="centered m-1">
          <Button className="m-1" onClick={goToCheckout}>Next</Button>
        </div>
        <div className="centered m-1">
          <a href="mailto:info@bballytics.com">
            <h3 className="no-bold">For more information, <span>contact us!</span></h3>
          </a>
          <h3>info@bballytics.com</h3>
        </div>
        </div>
      </section>
    </>
  );
};

export default Subscriptions;
