import { Link } from "react-router-dom";
import logo from "assets/images/logo/logo-512.png";
import "./LogoLink.scss";
import { config } from "api";

const LogoLink = () => {
  return (
    <Link className="logo-link" to={config.APP_PATHS.MAIN}>
      <div className="logo-img">
        <img src={logo} alt="basketball" />
        <h1>BBALLYTICS</h1>
      </div>
    </Link>
  );
};

export default LogoLink;
