import { config } from "api";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logout } from "state";
import "./LogoutButton.scss";

const LogoutButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    history.push(config.APP_PATHS.LOGIN);
  };

  return (
    <Link onClick={handleLogout} to={""} className="logout">
      Log Out
    </Link>
  );
};

export default LogoutButton;
