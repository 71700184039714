import { Api, config } from "api";
import { Card } from "components/UI";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUrlParam, postRequest } from "utils";

import "./ConfirmEmail.scss";

const ConfirmEmail = () => {
  const [result, setResult] = useState("");

  useEffect(() => {
    const id = getUrlParam("id");
    const token = getUrlParam("token");

    postRequest(Api.ENDPOINTS.CONFIRM_EMAIL, { id: id, token: token })
      .then(() => {
        setResult("Your email has been confirmed!");
      })
      .catch(() => {
        setResult("Something went wrong, please try again.");
      });
  }, []);

  return (
    <Card>
      <h5>{result}</h5>
      <br />
      <h5>
        <Link className="link" to={config.APP_PATHS.LOGIN}>
          Log In
        </Link>
      </h5>
    </Card>
  );
};

export default ConfirmEmail;
