import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import LogoLink from "../LogoLink/LogoLink";
import "./Form.scss";

type FormProps = {
  onSubmit: any;
  submitButtonName: string;
  isLoading: boolean;
  errorText: string;
  children?: any;
  childrenAfterSubmitButton?: any;
};

const Form = (props: FormProps) => {
  return (
    <div className="ui-form-div">
      <form autoComplete="off" className="ui-form" onSubmit={props.onSubmit}>
        <div className="logo-link">
          <LogoLink />
        </div>

        {props.children}

        {props.errorText !== "" && <div className="error">{props.errorText}</div>}

        <button type="submit" className="submit-button">
          {props.isLoading ? <LoadingSpinner /> : props.submitButtonName}
        </button>

        {props.childrenAfterSubmitButton}
      </form>
    </div>
  );
};

export default Form;
