import { config } from "api";
import { AccountSubscription } from "components";
import { Link } from "react-router-dom";
import LogoutButton from "../Buttons/LogoutButton";
import LogoLink from "../LogoLink/LogoLink";
import "./HomeHeader.scss";
import { selectIsAuth, useAppSelector } from "state";

type HeaderProps = {
  hideStatistics?: boolean;
};

const HomeHeader = (props: HeaderProps) => {
  const isAuth = useAppSelector(selectIsAuth);

  const subscription = props.hideStatistics ? (
    <></>
  ) : (
    <>
      <AccountSubscription />
      <Link to={config.APP_PATHS.STATISTICS} className="statistics-button">
        Statistics
      </Link>
    </>
  );

  return (
    <div className="home-header">
      <div className="home-link-div">
        <LogoLink />
      </div>

      <div
        className={`header-actions ${
          props.hideStatistics ? "header-actions-close" : ""
        }`}
      >
        {subscription}
        {isAuth && <LogoutButton />}
      </div>
    </div>
  );
};

export default HomeHeader;
