import { config } from "api";
import { NotFound } from "components";
import {
  Checkout,
  CheckoutConfirmed,
  ConfirmEmail,
  ForgotPassword,
  Home,
  Landing,
  Login,
  ResetPassword,
  Signup,
  Statistics,
  Subscriptions,
} from "pages";
import { Toaster } from "react-hot-toast";
import { Redirect, Route, Switch } from "react-router";
import { selectIsAuth, useAppSelector } from "state";
import { getCurrentUrlParamsString } from "utils";

function App() {
  const isAuth = useAppSelector(selectIsAuth);
  const currentUrlParams = getCurrentUrlParamsString();

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          error: {
            style: {
              border: "1px solid #F0B0B0",
              backgroundColor: "#fddddd",
            },
          },
        }}
      />

      <Switch>
        <Route exact path={config.APP_PATHS.LOGIN}>
          {isAuth ? (
            <Redirect
              to={
                currentUrlParams
                  ? `${config.APP_PATHS.STATISTICS}?${currentUrlParams}`
                  : config.APP_PATHS.MAIN
              }
            />
          ) : (
            <Login />
          )}
        </Route>

        <Route exact path={config.APP_PATHS.SIGNUP}>
          {isAuth ? <Redirect to={config.APP_PATHS.MAIN} /> : <Signup />}
        </Route>

        <Route exact path={config.APP_PATHS.CONFIRM_EMAIL}>
          {isAuth ? <Redirect to={config.APP_PATHS.MAIN} /> : <ConfirmEmail />}
        </Route>

        <Route exact path={config.APP_PATHS.FORGOT_PASSWORD}>
          {isAuth ? (
            <Redirect to={config.APP_PATHS.MAIN} />
          ) : (
            <ForgotPassword />
          )}
        </Route>

        <Route exact path={config.APP_PATHS.RESET_PASSWORD}>
          {isAuth ? <Redirect to={config.APP_PATHS.MAIN} /> : <ResetPassword />}
        </Route>

        <Route exact path={config.APP_PATHS.SUBSCRIPTIONS}>
          {isAuth ? (
            <Subscriptions />
          ) : (
            <Redirect to={config.APP_PATHS.LOGIN} />
          )}
        </Route>

        <Route exact path={config.APP_PATHS.CHECKOUT}>
          {isAuth ? <Checkout /> : <Redirect to={config.APP_PATHS.LOGIN} />}
        </Route>

        <Route exact path={config.APP_PATHS.CHECKOUT_CONFIRMED}>
          {isAuth ? (
            <CheckoutConfirmed />
          ) : (
            <Redirect to={config.APP_PATHS.LOGIN} />
          )}
        </Route>

        <Route exact path={config.APP_PATHS.STATISTICS}>
          <Statistics />
        </Route>

        <Route exact path={config.APP_PATHS.MAIN}>
          {isAuth ? <Home /> : <Landing />}
        </Route>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
