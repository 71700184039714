import { Api, config, useApiCall } from "api";
import champions from "assets/images/competitions/champions.png";
import eurocup from "assets/images/competitions/eurocup.jpg";
import euroleague from "assets/images/competitions/euroleague.jpg";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { SubscriptionLeague } from "utils";
import { Dropdown } from "components/UI";

import "./Competitions.scss";

const Competitions = () => {
  const { makeRequest: getLeagues, response } = useApiCall<SubscriptionLeague[]>();
  let mql = window.matchMedia("(max-width: 600px)");
  const history = useHistory();
  const [domesticLeagues, setdomesticLeagues] = useState<SubscriptionLeague[]>([]);
  const baseLeagues = ["EuroLeague", "EuroCup", "Champions League"]

  const handleWhereTo = (query: string) => {
    return `${config.APP_PATHS.STATISTICS}?league=${query}`;
  };

  useEffect(() => {
    getLeagues(config.API_ENDPOINTS.LEAGUES, {}, {}, Api.METHODS.GET, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response?.status !== 200) { return; }
    setdomesticLeagues(response.data.filter((league) => baseLeagues.includes(league.name) === false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const showLeague = (leagueName: string) => {
    history.push(`${config.APP_PATHS.STATISTICS}?league=${leagueName}`)
  }

  return (
    <section id="competitions" className="competitions">
      <div className="section-divider" />

      <div className="logos">
        <div className="logo">
          <Link to={handleWhereTo("EuroLeague")}>
            <h2>EuroLeague</h2>
            <img src={euroleague} alt="euroleague statistics" />
          </Link>
        </div>
        <div className="logo">
          <Link to={handleWhereTo("EuroCup")}>
            <h2>EuroCup</h2>
            <img src={eurocup} alt="eurocup statistics" />
          </Link>
        </div>
        <div className="logo">
          <Link to={handleWhereTo("Champions League")}>
            <h2>Champions</h2>
            <img className="champions-img" src={champions} alt="champions statistics" />
          </Link>
        </div>
        {domesticLeagues && (
          <div className="competitions-domestic-leagues">
            <Dropdown current="Other" handleFilterChange={showLeague} isMedia={mql.matches} options={domesticLeagues} />
          </div>
        )}
      </div>
    </section>
  );
};

export default Competitions;
