import Competitions from "components/Competitions/Competitions";
import MainTitle from "components/MainTitle/MainTitle";
import Layout from "components/Layout/Layout";
import Vision from "components/Vision/Vision";

const Landing = () => {
  return (
    <Layout>
      <MainTitle />
      <Competitions />
      <Vision />
    </Layout>
  );
};

export default Landing;
