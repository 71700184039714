import HomeCompetitions from "components/HomeCompetitions/HomeCompetitions";
import email from "assets/icons/email.png";
import { HomeHeader } from "components";
import { Link } from "react-router-dom";
import { config } from "api";

import "./Home.scss";

const Home = () => {
  return (
    <>
      <HomeHeader />
      <div className="home">
        <div className="centered">
          <h2>Welcome to BBallytics</h2>
        </div>

        <div className="centered subscribe-div">
          <h3>
            <Link to={config.APP_PATHS.SUBSCRIPTIONS}>
              <span className="subscribe-link">Subscribe</span>
            </Link>{" "}
            to access win probabilities and more!
          </h3>
        </div>

        <HomeCompetitions />

        <div className="centered contact-div">
          <a href="mailto:info@bballytics.com">
            <img src={email} alt="email icon" />
            <h3>Contact us!</h3>
          </a>
          <h3>info@bballytics.com</h3>
        </div>
      </div>
    </>
  );
};

export default Home;
