import axios from "axios";
import { config } from "./config";
import { STORAGE_KEYS, getFromLocalStorageWithExpiry, removeFromLocalStorage, setToLocalStorageWithExpiry, token_lifetime } from "utils";

axios.defaults.baseURL = process.env.NODE_ENV === "production" ? config.API_BASE_URL_PROD : config.API_BASE_URL_DEV;

const axiosAuth = axios.create();

// Request interceptor for API requests
axiosAuth.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${getFromLocalStorageWithExpiry(STORAGE_KEYS.ACCESS).item}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// Response interceptor for API responses
axiosAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refresh = getFromLocalStorageWithExpiry(STORAGE_KEYS.REFRESH).item;
      if (!refresh) {
        return Promise.reject(error);
      }
      try {
        const access_token = await Api.request(Api.ENDPOINTS.REFRESH, {}, { refresh: refresh }, Api.METHODS.POST, true);
        setToLocalStorageWithExpiry(STORAGE_KEYS.ACCESS, access_token.data.access, token_lifetime.accessToken);
        setToLocalStorageWithExpiry(STORAGE_KEYS.REFRESH, access_token.data.refresh, token_lifetime.refreshToken);
        axiosAuth.defaults.headers.common["Authorization"] = "Bearer " + access_token.data.access;
      } catch (e) {
        console.log("Failed to refresh");
        console.log(e);
        try {
          removeFromLocalStorage(STORAGE_KEYS.ACCESS);
          removeFromLocalStorage(STORAGE_KEYS.REFRESH);
          window.location.href = "/";
        } catch {}
        return Promise.reject(error);
      }
      return axiosAuth(originalRequest);
    }
    return Promise.reject(error);
  }
);

export class Api {
  static ENDPOINTS = config.API_ENDPOINTS;
  static METHODS = {
    GET: "get",
    POST: "post",
    PATCH: "patch",
    PUT: "put",
    DELETE: "delete",
  };

  static async request(endpoint: string, queryParams: {}, body: {}, method: any, noAuth: boolean) {
    const config = {
      url: endpoint,
      method: method,
      params: queryParams,
      headers: {},
      data: body,
    };
    if (noAuth) {
      return axios.request(config);
    }
    return axiosAuth.request(config);
  }
}
