import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AuthState,
  DefaultErrors,
  LoginErrors,
  LoginUserData,
  STORAGE_KEYS,
  getFromLocalStorageWithExpiry,
  postRequest,
  removeFromLocalStorage, setToLocalStorageWithExpiry, token_lifetime
} from "utils";
import { RootState } from "./store";
import { config } from "api/config";

const initialState: AuthState = {
  isAuth: false,
  isLoading: false,
  errorMessage: "",
  subscribedUntil: null,
};

const token = getFromLocalStorageWithExpiry(STORAGE_KEYS.ACCESS, false);
const refreshToken = getFromLocalStorageWithExpiry(STORAGE_KEYS.REFRESH, false);
if (token.item && refreshToken.item && refreshToken.expired === false) {
  initialState.isAuth = true;
} else {
  removeFromLocalStorage(STORAGE_KEYS.ACCESS);
  removeFromLocalStorage(STORAGE_KEYS.REFRESH);
}

export const login = createAsyncThunk("auth/login", async (userData: LoginUserData, { rejectWithValue }) => {
  try {
    const response = await postRequest(config.API_ENDPOINTS.LOGIN, userData);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.errorMessage = "";
    },
    logout: (state) => {
      removeFromLocalStorage(STORAGE_KEYS.ACCESS);
      state.isAuth = false;
    },
    sessionAuthState: (state, action) => {
      state.isAuth = action.payload;
    },
    setSubscribedUntil: (state, action) => {
      state.subscribedUntil = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuth = true;
        setToLocalStorageWithExpiry(STORAGE_KEYS.ACCESS, `${action.payload.access}`, token_lifetime.accessToken);
        setToLocalStorageWithExpiry(STORAGE_KEYS.REFRESH, `${action.payload.refresh}`, token_lifetime.refreshToken);
      })
      .addCase(login.rejected, (state, action: any) => {
        state.isLoading = false;

        if (action.payload.status === 401) {
          state.errorMessage = LoginErrors.INCORRECT_INPUT;
        } else {
          state.errorMessage = DefaultErrors.DEFAULT;
        }
      });
  },
});

export const { logout, sessionAuthState, clearState, setSubscribedUntil } = authSlice.actions;

export const selectIsAuth = (state: RootState) => state.auth.isAuth;
export const selectState = (state: RootState) => state.auth;
export const selectSubscribedUntil = (state: RootState) => state.auth.subscribedUntil;

export default authSlice.reducer;
